export const AVATAR_API = "https://ui-avatars.com/api";
export const API = "https://dev-api.genolens.com/api";
//export const API = "http://localhost:5556/api";
export const AUTH_TOKEN_ACCESS = "access_token";
export const AUTH_TOKEN_REFRESH = "refresh_token";
export const USER_ID = "user_id";
export const BEARER = "Bearer";
//export const DOMAIN = "http://localhost:3000";
export const DOMAIN = "https://dev.genolens.com";
export const GO_DATABASE = "http://amigo.geneontology.org/amigo/term/";
export const GENECARD_DATABASE = "https://www.genecards.org/cgi-bin/carddisp.pl?gene=";
