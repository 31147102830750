import axiosInstance from "axiosApi";
import { API } from "../constants";
import { AUTH_TOKEN_REFRESH } from "../constants";

class APIService {
  getGenolists() {
    return axiosInstance.get(API + "/genolists/");
  }
  getGenolist(id) {
    return axiosInstance.get(API + "/genolists/" + id);
  }

  getComparison(id) {
    return axiosInstance.get(API + "/comparisons/" + id);
  }

  getFactor(id) {
    return axiosInstance.get(API + "/factors/" + id);
  }

  getSample(id) {
    return axiosInstance.get(API + "/samples/" + id);
  }

  getSamplesByFactor(id) {
    return axiosInstance.get(API + "/samples/?factors=" + id);
  }

  getProjectsByUser(id) {
    return axiosInstance.get(API + "/projects?owner=" + id);
  }

  getProject(id) {
    return axiosInstance.get(`${API}/projects/${id}`);
  }

  getProjectReport(id) {
    return axiosInstance.get(`${API}/projects/${id}/download_report/`,{
      responseType: "blob", // Important pour traiter les fichiers binaires
    });
  }

  /**
   * Retrieves a comprehensive version of a project.
   * @param {*} id project's id
   * @returns A JSON containing the project data as well as its samples,
   * comparisons and factors
   */
  getExtendedProject(id) {
    return axiosInstance.get(`${API}/projects/${id}?extended=true`);
  }

  getMyGenolists() {
    return axiosInstance.get(API + "/genolists/mygenolists");
  }

  getPublicGenolists() {
    return axiosInstance.get(API + "/genolists/public");
  }

  getGenolistsByProject(id) {
    return axiosInstance.get(API + "/genolists/?project=" + id);
  }

  getSequenceDistributionByProject(id) {
    return axiosInstance.get(API + "/projects/" + id + "/sequence-distribution");
  }

  getGenesDetectedByProject(id) {
    return axiosInstance.get(API + "/projects/" + id + "/genes-detected");
  }

  getPCAdataByProject(id) {
    return axiosInstance.get(API + "/projects/" + id + "/pca-data");
  }

  getSamplesCorrelationByProject(id) {
    return axiosInstance.get(API + "/projects/" + id + "/samples-correlation");
  }

  getSamplesQualityControlByProject(id) {
    return axiosInstance.get(API + "/projects/" + id + "/samples-quality-control");
  }

  getTranscriptoValuesByGenolist(id) {
    return axiosInstance.get(API + "/transcriptovalues/?genolist=" + id);
  }

  getTranscriptoValuesToDownload(genolistId) {
    return axiosInstance.get(
      API + "/genolists/" + genolistId + "/download-transcripto-values",
      { responseType: "blob" }
    );
  }

  getProteoValuesByGenolist(genolistId) {
    return axiosInstance.get(API + "/proteovalues/?genolist=" + genolistId);
  }

  getVolcanoAndMAPlot(genolistId) {
    return axiosInstance.get(API + "/genolists/" + genolistId + "/volcano-MA-data");
  }

  getBoxplotFromGenolistAndGenoref(genolist_id, selectedGenoref_id) {
    return axiosInstance.get(
      API + "/genolists/" + genolist_id + "/boxplot-data?genoref=" + selectedGenoref_id
    );
  }

  getGOvaluesByGenolist(genolistId) {
    return axiosInstance.get(API + "/govalues/?genolist=" + genolistId);
  }

  getGenorefsForGOvalue(goValueId) {
    return axiosInstance.get(API + "/govalues/" + goValueId + "/genorefs");
  }

  getMyProjects() {
    return axiosInstance.get(API + "/projects/myprojects");
  }

  getPublicProjects() {
    return axiosInstance.get(API + "/projects/public");
  }

  getDashesByUser(id) {
    return axiosInstance.get(API + "/dashes?owner=" + id);
  }

  //   getMaximumDashesByLevelSubscription(level) {
  //     return axiosInstance.get(API + "/subscription-levels?name=" + level);
  //   }

  getSubscriptionByUser(id) {
    return axiosInstance.get(API + "/subscriptions?user=" + id);
  }

  getNotificationsByUser(id) {
    return axiosInstance.get(API + "/notifications/mynotifications");
  }

  postLogoutUser() {
    console.log("refresh token: " + localStorage.getItem(AUTH_TOKEN_REFRESH));
    axiosInstance
      .post(API + "/logout/", {
        refresh_token: localStorage.getItem(AUTH_TOKEN_REFRESH),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error logout: " + error);
      });
  }

  /**
   * Retrieves a prefilled template based on the provided project id.
   * @param {*} projectId
   * @returns
   * A binary that must be downloaded through an link (the DownloadButton component
   * is designed to handle the response of this method)
   */
  getExcelFileByProject(projectId) {
    return axiosInstance.get(`${API}/upload?projectId=${projectId}`, { responseType: "blob" });
  }

  createProject(projectData) {
    return axiosInstance.post(API + "/projects/", projectData, {
      headers: {
        ...axiosInstance.headers,
        "Content-Type": "application/json",
      },
    });
  }

  /**
   * Sends a PUT request to update a specific project.
   * @param {*}
   * formData must be a JSON with any of the Project model
   * fields. The id must be provided to target the project. All
   * other keys are optional.
   * @returns
   * The serialized updated project
   */
  putProject(formData) {
    return axiosInstance.put(`${API}/projects/${formData.id}/`, formData, {
      headers: {
        ...axiosInstance.headers,
        "Content-Type": "application/json",
      },
    });
  }

  /**
   * Sends the Excel provided through an UploadButton.
   * This method is meant to be used before postProjectDesc.
   * @param {*}
   * formData must be a xlsx file.
   * @returns If valid, the parsed data in the structure expected by postProjectDesc.
   * If not, one or several errors. Business/models constraint violations are returned
   * with 422 status code, in order to handle them through a specific screen.
   */
  postExcelFile(formData) {
    return axiosInstance.post(`${API}/upload/`, formData, {
      headers: {
        ...axiosInstance.headers,
        "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    });
  }

  /**
   * Sends the data to be saved in DB.
   * This method is meant to be used after confirming the data returned by postExcelFile
   * @param {*} data must have the exact structure that is returned by postExcelFile
   * @returns a 201 if inserted.
   */
  postProjectDesc(data) {
    return axiosInstance.post(`${API}/uploadreviewed/`, data, {
      headers: {
        ...axiosInstance.headers,
        "Content-Type": "application/json",
      },
    });
  }

  /**
   * Deletes project samples, factors, comparisons.
   * Do not use this method if project is started
   * @param {*} id of the project
   * @returns 200 and a string with the number of entries deleted.
   */
  deleteProjectDesc(id) {
    return axiosInstance.delete(`${API}/uploadreviewed/`, {
      headers: {
        ...axiosInstance.headers,
        "Content-Type": "application/json",
      },
      params: {
        projectId: id,
      },
    });
  }
}

export default new APIService();
